// #region Global Imports
import * as React from 'react';
import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import { Router } from 'next/router';
// #endregion Global Imports
// #region Local Imports
import 'react-day-picker/dist/style.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '../components/App/styles.sass';
import { useApollo } from '@services/apollo';
import { Spinner } from '@components/Spinner';
// #endregion Local Imports
export default function App({
  Component,
  pageProps,
}: AppProps<Record<string, unknown>>): React.ReactElement {
  const apolloClient = useApollo(pageProps);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    Router.events.on('routeChangeStart', () => {
      setLoading(true);
    });

    Router.events.on('routeChangeComplete', () => {
      setLoading(false);
    });

    Router.events.on('routeChangeError', () => {
      setLoading(false);
    });
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <div style={{ pointerEvents: loading ? "none" : "initial" }}>
        <Component {...pageProps} />
      </div>
      {loading ? <Spinner /> : null}
    </ApolloProvider>
  );
}
