import React from 'react';
import styles from './Spinner.module.sass';

export const Spinner = (): React.ReactElement => {
  return (
    <div className={styles.Spinner_root}>
      <div className={styles.Spinner}>
        <div className={styles.SpinnerInner} />
      </div>
    </div>
  );
};
