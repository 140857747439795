// Apollo GraphQL client

// ----------------------------------------------------------------------------
// IMPORTS
import { useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import extend from 'lodash/extend';
import merge from 'lodash/merge';
import type { NormalizedCacheObject } from '@apollo/client';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import type { GetServerSidePropsContext } from 'next';
import type { LanguageId } from '@generated/graphql';
import type { JwtToken } from '@contexts/AuthContext';
import type { PreferenceCookieInterface } from '@contexts/CookieContext';
import generatedIntrospection from '../../introspection.json';

// ----------------------------------------------------------------------------

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

const isProduction = process.env.NODE_ENV === 'production';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

export function createApolloClient(
  token?: string,
): ApolloClient<NormalizedCacheObject> {
  // Create the cache first, which we'll share across Apollo tooling.
  // This is an in-memory cache. Since we'll be calling `createClient` on
  // universally, the cache will survive until the HTTP request is
  // responded to (on the server) or for the whole of the user's visit (in
  // the browser)

  // Match up fragments
  const cache = new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
  });

  // Create a HTTP client (both server/client). It takes the GraphQL
  // server from the `GRAPHQL` environment variable, which by default is
  // set to an external playground at https://graphqlhub.com/graphql
  const httpLink = new HttpLink({
    credentials: 'same-origin',
    uri: String(process.env.NEXT_PUBLIC_BACKEND).concat('/graphql'),
  });

  // If we're in the browser, we'd have received initial state from the
  // server. Restore it, so the client app can continue with the same data.
  if (!(typeof window === 'undefined')) {
    if (isProduction) {
      cache.restore((window as any)[APOLLO_STATE_PROP_NAME]);
    }
  }

  const authLink = setContext((_, { headers }): Record<string, unknown> => {
    if (token) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    }

    return headers;
  });

  // Return a new Apollo Client back, with the cache we've just created,
  // and an array of 'links' (Apollo parlance for GraphQL middleware)
  // to tell Apollo how to handle GraphQL requests
  return new ApolloClient({
    connectToDevTools: process.env.NODE_ENV === 'development' && true,
    cache,
    link: authLink.concat(
      ApolloLink.from([
        // General error handler, to log errors back to the console.
        // Replace this in production with whatever makes sense in your
        // environment. Remember you can use the global `SERVER` variable to
        // determine whether you're running on the server, and record errors
        // out to third-party services, etc
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors?.map(({ message, locations, path }) =>
              // eslint-disable-next-line no-console
              console.error(
                `[GraphQL error]: Message: ${message}, Location: ${String(
                  locations,
                )}, Path: ${String(path)}`,
              ),
            );
          }
          if (networkError) {
            // eslint-disable-next-line no-console
            console.error(`[Network error]: ${JSON.stringify(networkError)}`);
          }
        }),
        httpLink,
      ]),
    ),
    // On the server, enable SSR mode
    ssrMode: typeof window === 'undefined',
    queryDeduplication: false,
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
    }
  });
}

export function initializeApollo(
  initialState?: unknown,
  token?: string
): ApolloClient<NormalizedCacheObject> {
  // If there is none or forceRecreation is true we create a new client; else we dont.
  const _apolloClient = apolloClient ?? createApolloClient(token);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray: unknown[], sourceArray: unknown[]) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data as NormalizedCacheObject);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState<T>(
  context: GetServerSidePropsContext,
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: T & Record<string, any>,
): T & {
  props: {
    language: {
      locale: string;
      languageId: LanguageId;
    };
    user: {
      token: string | null;
      preferences: PreferenceCookieInterface | null;
    };
  };
} {
  const cache = client.cache.extract();

  // The.replace() is necessary to prevent script injection attacks
  pageProps.props[APOLLO_STATE_PROP_NAME] = JSON.parse(
    JSON.stringify(cache).replace(/</g, '\\u003c'),
  );

  pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();

  return withDefaultPageProps(context, pageProps);
}

export function useApollo<T>(
  pageProps: T & Record<string, any>,
): ApolloClient<NormalizedCacheObject> {
  const state: unknown = pageProps[APOLLO_STATE_PROP_NAME];
  return useMemo(() => initializeApollo(state, pageProps.user?.token), [pageProps.user?.token, state]);
}

function withDefaultPageProps<T>(
  context: GetServerSidePropsContext & {
    req: { jwt?: JwtToken; preferences?: PreferenceCookieInterface };
  },
  obj: T & Record<string, any>,
): T & {
  props: {
    language: {
      locale: string;
      languageId: LanguageId;
    };
    user: {
      token: string | null;
      preferences: PreferenceCookieInterface | null;
    };
  };
} {
  return {
    ...obj,
    props: {
      ...obj.props,
      language: {
        locale: context.locale,
        languageId: context.locale?.toUpperCase() as LanguageId,
      },
      user: {
        token: !isEmpty(context.req.jwt) && context.req.jwt || null,
        preferences: context.req.preferences ?? null,
      },
    },
  };
}

export function withDefaultVariables<T>(
  context: GetServerSidePropsContext,
  obj: T,
): T & {
  language: LanguageId;
  path: string;
  translate: LanguageId;
} {
  return extend(obj, {
    language:
      context.locale?.toLowerCase() !== 'default'
        ? (context.locale?.toUpperCase() as LanguageId)
        : ('DE' as LanguageId),
    path: String(context.locale?.concat(context.resolvedUrl)),

    // NOTE: This is pretty basic, expand it, if needed.
    translate:
      context.locale === 'de' ? ('FR' as LanguageId) : ('DE' as LanguageId),
  });
}
